import {
  Button,
  Card,
  Flex,
  Heading,
  Link,
  useTheme,
  View,
} from "@aws-amplify/ui-react"
import React, { useContext } from "react"
import { AuthContext } from "./AuthContext"
import { RiMailCheckFill, RiMailForbidFill } from "react-icons/ri"
import ChangePasswordForm from "./ChangePasswordForm"
import {
  Navigate,
  NavLink,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom"
import AuthHOC from "./AuthHOC"

const ProfileLayout = () => {
  const { userMeta } = useContext(AuthContext)
  const { tokens } = useTheme()

  return (
    <View>
      <Card>
        <Link as={NavLink} to="/">
          INVESTORS
        </Link>
        <Heading level={2}>{userMeta.name}</Heading>
        <Flex justifyContent="space-between">
          <Flex>
            <Link
              style={({ isActive }) => ({
                color: isActive
                  ? tokens.colors.white
                  : tokens.colors.neutral[60],
                backgroundColor: isActive
                  ? tokens.colors.neutral[90]
                  : "inherit",
                padding: 5,
              })}
              as={NavLink}
              to="../"
            >
              General
            </Link>
            {/* <Link
              style={({ isActive }) => ({
                color: isActive
                  ? tokens.colors.white
                  : tokens.colors.neutral[60],
                backgroundColor: isActive
                  ? tokens.colors.neutral[90]
                  : "inherit",
                padding: 5,
              })}
              as={NavLink}
              to="password"
            >
              Password
            </Link> */}
          </Flex>

          <AuthHOC group="isAdmin">
            <Link
              style={({ isActive }) => ({
                color: isActive
                  ? tokens.colors.white
                  : tokens.colors.neutral[60],
                backgroundColor: isActive
                  ? tokens.colors.neutral[90]
                  : "inherit",
                padding: 5,
              })}
              as={NavLink}
              to="access"
            >
              Access
            </Link>
          </AuthHOC>
        </Flex>
        <View>
          <Outlet />
        </View>
      </Card>
    </View>
  )
}

const General = () => {
  const { signOut, userMeta } = useContext(AuthContext)
  const navigate = useNavigate()
  return (
    <View>
      <Card>
        <Flex direction="column" gap={2}>
          <Heading level={5}>Username: {userMeta.username}</Heading>
          <Flex alignItems="flex-end" gap={2}>
            {userMeta.email_verified ? (
              <RiMailCheckFill color="green" />
            ) : (
              <RiMailForbidFill color="red" />
            )}
            <View>{userMeta.email}</View>
          </Flex>
        </Flex>
      </Card>
      <Card textAlign="right">
        <Button
          isFullWidth={true}
          onClick={() => {
            navigate("/")
            signOut()
          }}
          variation="primary"
        >
          Sign Out
        </Button>
      </Card>
    </View>
  )
}

const Access = () => {
  const { userMeta, showAdminOverlay, setAdminOverlay } =
    useContext(AuthContext)

  return (
    <View>
      <AuthHOC group="isAdmin">
        <Button
          onClick={() =>
            setAdminOverlay((is) => (is === "show" ? "hide" : "show"))
          }
        >
          {showAdminOverlay ? "Hide" : "Show"} Admin Overlays
        </Button>
        <Card>
          <Flex direction="column" gap={0}>
            {userMeta.groups.map((gp, inx) => (
              <View key={inx}>{gp}</View>
            ))}
          </Flex>
        </Card>
      </AuthHOC>
    </View>
  )
}

const Profile = () => {
  return (
    <Routes>
      <Route path="/" element={<ProfileLayout />}>
        <Route path="password" element={<ChangePasswordForm />} />
        <Route path="access" element={<Access />} />
        <Route index element={<General />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  )
}

export default Profile
