import { Text, View } from "@aws-amplify/ui-react"
import React from "react"

const NotAuthorized = () => {
  return (
    <View>
      <Text>Need Authorization</Text>
    </View>
  )
}

export default NotAuthorized
