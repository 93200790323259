import { Button, Card, Flex, Heading, Text } from "@aws-amplify/ui-react"
import React, { useContext } from "react"
import {
  Link,
  isRouteErrorResponse,
  useLocation,
  useRouteError,
} from "react-router-dom"
import { AuthContext } from "./AmplifyAuth/AuthContext"

const LoadingScreen = () => {
  const { pathName } = useLocation()
  const { signOut } = useContext(AuthContext)
  const error = useRouteError()
  if (isRouteErrorResponse(error) && error.status !== 400) {
    // console.log(error)
    return <h1>An Error occurred: {error.message}</h1>
  }
  return (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      <Card textAlign="center">
        <Flex direction="column" padding={50}>
          <Heading level={1}>
            Liver Capital <br />
            Investors
          </Heading>
          <Link to={pathName} style={{ color: "white" }}>
            <Text fontSize="1.2em">View Portfolios</Text>
          </Link>
        </Flex>

        <Button isFullWidth={true} onClick={signOut} variation="link">
          Sign Out
        </Button>
      </Card>
    </Flex>
  )
}

export default LoadingScreen
