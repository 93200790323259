const weekly_pnl_data = [
  {
    label: "Period",
    abbr_label: "P",
    field: "period",
    key: "period",
    sortBy: true,
    format: "date",
  },
  {
    label: "Portfolio Value",
    abbr_label: "PV",
    field: "portfolio_value",
    key: "portfolio_value",
    format: "USD",
  },
  {
    label: "ABS Return",
    abbr_label: "AR",
    field: "daily_return",
    key: "daily_return",
    format: "USD",
  },
  {
    label: "Cumulative ABS Return",
    abbr_label: "CAR",
    field: "cumulative_return",
    key: "cumulative_return",
    format: "USD",
  },
  {
    label: "Cumulative Return Pct",
    abbr_label: "CRP",
    field: "cumulative_return_pct",
    key: "cumulative_return_pct",
    format: "pct",
  },
  {
    label: "Leverage",
    abbr_label: "L",
    field: "rebased_lev",
    key: "leverage",
    format: "leverage",
  },
]

const pnl_data_config = (leverageToDisplay, weekly) =>
  weekly
    ? weekly_pnl_data
    : [
        {
          label: "Period",
          abbr_label: "P",
          field: "period",
          key: "period",
          sortBy: true,
          format: "date",
        },
        {
          label: "Portfolio Value",
          abbr_label: "PV",
          field: "portfolio_value",
          key: "portfolio_value",
          format: "USD",
        },
        {
          label: "Daily Return",
          abbr_label: "DR",
          field: "daily_return",
          key: "daily_return",
          format: "USD",
        },
        {
          label: "Cumulative Return",
          abbr_label: "CR",
          field: "cumulative_return",
          key: "cumulative_return",
          format: "USD",
        },
        {
          label: "Daily Return",
          abbr_label: "DR",
          field: "daily_return_pct",
          key: "daily_return_pct",
          format: "pct",
        },
        {
          label: "Cumulative Return",
          abbr_label: "RTL",
          field: "rebased",
          key: "rebased",
          format: "pct",
        },

        {
          label: "Return Cash",
          abbr_label: "RC",
          field: "return_cash",
          key: "return_cash",
          format: "pct",
        },

        {
          label: "Cumulative Return Cash",
          abbr_label: "CRC",
          field: "cumulative_return_cash",
          key: "cumulative_return_cash",
          format: "pct",
        },

        {
          label:
            leverageToDisplay === "REALIZED_LEVERAGE"
              ? "Realized Leverage"
              : leverageToDisplay === "LEVERAGE_CASH"
              ? "Leverage"
              : "Leverage",
          abbr_label:
            leverageToDisplay === "REALIZED_LEVERAGE"
              ? "RL"
              : leverageToDisplay === "LEVERAGE_CASH"
              ? "L"
              : "L",
          field:
            leverageToDisplay === "REALIZED_LEVERAGE"
              ? "realized_leverage"
              : leverageToDisplay === "LEVERAGE_CASH"
              ? "leverage_cash"
              : "leverage",
          format: "leverage",
          key: "leverage",
        },
      ]

export default pnl_data_config
