import React from "react"
import { Card, Heading, useTheme } from "@aws-amplify/ui-react"
import ValueChangeIcon from "../../../../../../components/ValueChangeIcon"
import DateFormat from "../../../../../../components/DateFormat"
import { pnlRowStyle } from "../../../../../../lib/utils"

const valueFormat = (v) => `${v?.toFixed(2)}`
const dollarValueFormat = (v) =>
  `${v?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  })}`
const pctValueFormat = (v) => `${v}%`
const pctFixedValueFormat = (v) => `${v?.toFixed(2)}%`

const DailyPnlTooltip = ({ data, showDollar }) => {
  return (
    <>
      {showDollar && (
        <tr>
          <td>Daily Return</td>
          <td>
            <ValueChangeIcon
              value={data.daily_return}
              formatFn={dollarValueFormat}
            />
          </td>
        </tr>
      )}
      {showDollar && (
        <tr>
          <td>Cumulative Return</td>
          <td>
            <ValueChangeIcon
              value={data.cumulative_return}
              formatFn={dollarValueFormat}
            />
          </td>
        </tr>
      )}
      <tr>
        <td>Daily Return</td>
        <td>
          <ValueChangeIcon
            value={data.daily_return_pct}
            formatFn={pctFixedValueFormat}
          />
        </td>
      </tr>
      <tr>
        <td>Cumulative Return</td>
        <td>
          <ValueChangeIcon
            value={data.cumulative_return_pct}
            formatFn={pctFixedValueFormat}
          />
        </td>
      </tr>
    </>
  )
}

const WeeklyPnlTooltip = ({ data }) => {
  return (
    <>
      <tr>
        <td>ABS Return</td>
        <td>
          <ValueChangeIcon
            value={data.daily_return}
            formatFn={dollarValueFormat}
          />
        </td>
      </tr>
      <tr>
        <td>Cumulative Return</td>
        <td>
          <ValueChangeIcon
            value={data.rebased_return}
            formatFn={dollarValueFormat}
          />
        </td>
      </tr>
      <tr>
        <td>Cumulative Return Pct</td>
        <td>
          <ValueChangeIcon
            value={data.cumulative_return_pct?.toFixed(2)}
            formatFn={pctValueFormat}
          />
        </td>
      </tr>
      <tr>
        <td>Return on Trade Level</td>
        <td>
          <ValueChangeIcon
            value={data.return_cash}
            formatFn={pctFixedValueFormat}
          />
        </td>
      </tr>
    </>
  )
}

const CreateCustomPNLTooltip = (
  data,
  leverage,
  leverageToDisplay,
  isWeekly,
  benchmarkDisplayName,
  showLeverage,
  showDollar = false
) => {
  const { tokens } = useTheme()
  const leverageStyle = { color: tokens.colors.metagora.leverage.value }

  return ({ payload, label, active }) => {
    if (active && payload) {
      const this_data = data.find((d) => d.period === label)
      const leverageField =
        leverageToDisplay === "REALIZED_LEVERAGE"
          ? this_data?.realized_leverage
          : leverageToDisplay === "LEVERAGE_CASH"
          ? this_data?.leverage_cash
          : this_data?.leverage

      return (
        !!this_data && (
          <Card
            backgroundColor={tokens.colors.background.primary.value}
            padding={0}
          >
            <Heading level={4} padding={0}>
              <DateFormat date={label} format="DD-MMM-YY" />
            </Heading>

            <table>
              <tbody>
                {!!benchmarkDisplayName && !isWeekly && (
                  <tr>
                    <td
                      style={{
                        color: tokens.colors.metagora.benchmark_chart_stroke,
                        fontWeight: "bold",
                      }}
                    >
                      {benchmarkDisplayName}
                    </td>
                    <td>
                      <ValueChangeIcon
                        value={this_data.rebased_benchmark_return?.toFixed(2)}
                        formatFn={pctValueFormat}
                      />
                    </td>
                  </tr>
                )}
                {leverage && showLeverage && (
                  <tr>
                    <td style={leverageStyle}>
                      {leverageToDisplay === "REALIZED_LEVERAGE"
                        ? "Realized Leverage"
                        : "Leverage"}
                      [{leverage.toFixed(2)}]
                    </td>
                    <td style={pnlRowStyle(leverageField, leverage, 3)}>
                      <ValueChangeIcon
                        value={leverageField}
                        disable_value_color
                        formatFn={valueFormat}
                      />
                    </td>
                  </tr>
                )}
                {showDollar && (
                  <tr>
                    <td>Portfolio Value</td>
                    <td>${this_data.portfolio_value.toLocaleString()}</td>
                  </tr>
                )}

                {isWeekly ? (
                  <WeeklyPnlTooltip data={this_data} />
                ) : (
                  <DailyPnlTooltip data={this_data} showDollar={showDollar} />
                )}
              </tbody>
            </table>
            {!this_data.official && (
              <small>
                <i>unofficial</i>
              </small>
            )}
          </Card>
        )
      )
    }

    return null
  }
}

export default CreateCustomPNLTooltip
