const config = {
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_nZDu0ajQZ",
    APP_CLIENT_ID: "2aa5835rh59llo3t21vr1deqie",
    IDENTITY_POOL_ID: "eu-west-1:358c8055-9f98-43c7-af3d-d169e2238707",
    GRAPHQL_ENDPOINT:
      "https://vu5quznrc5bstirph7yvwrdrcq.appsync-api.eu-west-1.amazonaws.com/graphql",
    APPSYNC_AUTH_TYPE: "AMAZON_COGNITO_USER_POOLS",
  },
}

const amplify_config = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  aws_appsync_graphqlEndpoint: config.cognito.GRAPHQL_ENDPOINT,
  aws_appsync_region: config.cognito.REGION,
  aws_appsync_authenticationType: config.cognito.APPSYNC_AUTH_TYPE,
}

export default amplify_config
