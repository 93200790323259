import { View } from "@aws-amplify/ui-react"
import _ from "lodash"
import React, { useMemo, useState } from "react"
import RespText from "../../../../components/RespText"
import PnlDataRow, { PnlWeeklyDataRow } from "./PnlDataRow"
import "./table_styles.css"

const HistoricalPNL = ({
  config,
  pnlData,
  leverageToDisplay,
  portLeverage,
  sorting,
  isWeekly,
  showLeverage = true,
  noDollar = false,
}) => {
  const [isDesc, setIsDesc] = useState("desc")
  const [sortField, setSortField] = useState("period")

  const data = useMemo(
    () => _.orderBy(pnlData, [sortField], [isDesc ? "desc" : "asc"]),
    [pnlData, isDesc, sortField]
  )
  // console.log(data)
  return (
    <table className="data-table">
      <thead>
        <tr>
          {config.map((cf) => (
            <th key={cf.field}>
              <View
                style={{
                  textDecoration: cf.field === sortField ? "underline" : "",
                  cursor: sorting
                    ? cf.field === sortField
                      ? `${isDesc ? "s" : "n"}-resize`
                      : "pointer"
                    : "inherit",
                }}
                onClick={() => {
                  if (cf.field === sortField) {
                    setIsDesc((dir) => !dir)
                  } else {
                    if (sorting) {
                      setSortField(cf.field)
                    }
                  }
                }}
              >
                <RespText computer={cf.label} mobile={cf.abbr_label} />
              </View>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((d, inx) =>
          isWeekly ? (
            <PnlWeeklyDataRow
              rowData={d}
              key={d.period + "_" + inx}
              leverageToDisplay={leverageToDisplay}
              portLeverage={portLeverage}
            />
          ) : (
            <PnlDataRow
              rowData={d}
              key={d.period + "_" + inx}
              leverageToDisplay={leverageToDisplay}
              portLeverage={portLeverage}
              showLeverage={showLeverage}
              noDollar={noDollar}
            />
          )
        )}
      </tbody>
    </table>
  )
}

export default HistoricalPNL
