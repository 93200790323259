import { useContext, useEffect } from "react"
import { RouterProvider } from "react-router-dom"
import ReactGA from "react-ga4"

import "./App.css"
import { Loader } from "@aws-amplify/ui-react"
import router from "./sections/router"
import { AuthContext } from "./components/AmplifyAuth/AuthContext"

function App() {
  const { userMeta } = useContext(AuthContext)
  useEffect(() => {
    ReactGA.set({ user_id: userMeta.sub })
    ReactGA.send("pageview")
  }, [userMeta.sub])
  return (
    <RouterProvider router={router} fallbackElement={<Loader size="large" />} />
  )
}

export default App
