import { View, useTheme } from "@aws-amplify/ui-react"
import React from "react"
import { NavLink, Outlet } from "react-router-dom"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Menu, MenuItem, Sidebar, menuClasses } from "react-pro-sidebar"
import { useQuery } from "@tanstack/react-query"
import { portfoliosQuery } from "./Portfolios/lib/usePortfolios"
import PortfolioMenuList from "./Portfolios/PortfolioList"

const Layout = () => {
  const { data: portfolios } = useQuery(portfoliosQuery())
  const { tokens } = useTheme()
  return (
    <View>
      <Outlet />
      <Sidebar
        backgroundColor="black"
        breakPoint="always"
        width="350px"
        collapsedWidth="260px"
      >
        <Menu
          transitionDuration={500}
          closeOnClick={true}
          rootStyles={{
            ["." + menuClasses.button]: {
              height: "1.8rem",
              backgroundColor: tokens.colors.background.primary.value,
              "&:hover": {
                backgroundColor: tokens.colors.background.primary.value,
                color: tokens.colors.font.secondary.value,
              },
            },
          }}
        >
          <MenuItem
            component={
              <NavLink
                to="/portfolios"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
              />
            }
          >
            INVESTORS
          </MenuItem>

          <PortfolioMenuList portfolios={portfolios} />
          <MenuItem
            component={
              <NavLink
                to="profile"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
              />
            }
          >
            PROFILE
          </MenuItem>
        </Menu>
      </Sidebar>
      <ReactQueryDevtools initialIsOpen={false} />
    </View>
  )
}

export default Layout
