import {
  Navigate,
  createBrowserRouter,
  Outlet,
  useRouteError,
  isRouteErrorResponse,
} from "react-router-dom"

import { PortfolioRouteErrorBoundary } from "./Portfolios/PortfolioLayout"
import PortfolioDetailLayout from "./Portfolios/PortfolioDetailLayout"
import Layout from "./Layout"
import PortfolioTable from "./Portfolios/PortfolioTable"
import HistoricalPage, {
  PNLRouteErrorBoundary,
} from "./Portfolios/elements/HistoricalPNL/HistoricalPage"
import ContractRollsPage from "./Information/ContractRolls/ContractRollsPage"

import Profile from "../components/AmplifyAuth/Profile"

import { Card, Flex, Heading } from "@aws-amplify/ui-react"
import LoadingScreen from "../components/LoadingScreen"
import { QueryClientProvider } from "@tanstack/react-query"
import queryClient from "../lib/queryClient"
import { ProSidebarProvider } from "react-pro-sidebar"

export const RouteErrorBoundary = () => {
  const error = useRouteError()
  if (!isRouteErrorResponse(error)) {
    throw error
  }
  return (
    <Card>
      <Heading>Route Error</Heading>
      <Flex>
        <pre>{JSON.stringify(error, true, 2)}</pre>
      </Flex>
    </Card>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <QueryClientProvider client={queryClient}>
        <ProSidebarProvider>
          <Layout />
        </ProSidebarProvider>
      </QueryClientProvider>
    ),
    errorElement: <LoadingScreen />,
    children: [
      {
        path: "portfolios",
        id: "portfolioTypes",
        element: <Outlet />,
        errorElement: <PortfolioRouteErrorBoundary />,
        children: [
          {
            index: true,
            element: <PortfolioTable />,
          },
          {
            path: ":portfolioSlug",
            id: "portfolio",
            element: <PortfolioDetailLayout />,
            children: [
              {
                index: true,
                errorElement: <PNLRouteErrorBoundary />,
                element: <HistoricalPage />,
              },
              {
                path: "contractrolls",
                element: <ContractRollsPage />,
              },
            ],
          },
        ],
      },
      {
        path: "profile/*",
        element: <Profile />,
      },
      {
        index: true,
        element: <Navigate to="portfolios" />,
      },
      {
        path: "*",
        element: <Navigate to="portfolios" />,
      },
    ],
  },
])

export default router
