import { Button, useTheme } from "@aws-amplify/ui-react"
import React from "react"
import RespText from "../../../../../components/RespText"

const ToggleTooltip = ({ showTooltip, setShowToolTip }) => {
  const { tokens } = useTheme()
  return (
    <Button
      onClick={() => setShowToolTip((is) => (is === "show" ? "hide" : "show"))}
      backgroundColor={tokens.colors.background.secondary}
      style={{ fontSize: "0.9em" }}
      variation="link"
    >
      <RespText
        mobile={!showTooltip ? "Show" : "Hide"}
        medium={!showTooltip ? "Show Labels" : "Hide Labels"}
        computer={!showTooltip ? "Show Labels" : "Hide Labels"}
      />
    </Button>
  )
}

export default ToggleTooltip
