import React, { useContext } from "react"
import { AuthContext } from "./AuthContext"
import NotAuthorized from "./NotAuthorized"
import { View } from "@aws-amplify/ui-react"

const GROUP_COLOR = {
  showVis: "blue",
  dailyModelSummary: "green",
  showRanges: "brown",
  showSorting: "violet",
  contractRolls: "purple",
  maintenanceMargin: "darkblue",
  spOverlay: "teal",
}

const AuthHOC = ({ children, group = "isAdmin", showWarning }) => {
  const { userMeta, showAdminOverlay } = useContext(AuthContext)
  return userMeta[group] || userMeta.isAdmin ? (
    userMeta.isAdmin && showAdminOverlay ? (
      <View
        style={{
          outline: `thin ${GROUP_COLOR[group]} solid`,
          position: "relative",
        }}
      >
        <View
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            backgroundColor: GROUP_COLOR[group],
            fontSize: ".6em",
          }}
        >
          {group}
        </View>
        {children}
      </View>
    ) : (
      children
    )
  ) : showWarning ? (
    <NotAuthorized />
  ) : null
}

export default AuthHOC
