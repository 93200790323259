import { Text } from "@aws-amplify/ui-react"
import React from "react"

const RespText = ({
  computer,
  medium,
  mobile,
  onlyMobile,
  tooltip,
  ...rest
}) => {
  return (
    <>
      {onlyMobile && mobile && <Text {...rest}>{mobile}</Text>}

      {!onlyMobile && mobile && (
        <Text display={{ base: "block", medium: "none" }} {...rest}>
          <abbr title={tooltip}>{mobile}</abbr>
        </Text>
      )}
      {!onlyMobile && (
        <Text
          display={{ base: "none", medium: "block" }}
          style={{ whiteSpace: "nowrap" }}
          {...rest}
        >
          {computer}
        </Text>
      )}
    </>
  )
}

export default RespText
