import React from "react"
import { useParams } from "react-router-dom"
import _ from "lodash"
import { View } from "@aws-amplify/ui-react"
import { useQuery } from "@tanstack/react-query"
import { portfolioQuery } from "../../Portfolios/lib/usePortfolios"

const ContractRollsPage = () => {
  // const portfolio = useRouteLoaderData("portfolio")
  const { portfolioSlug } = useParams()
  const { data } = useQuery({
    ...portfolioQuery(portfolioSlug),
    select: (d) => d.contractRolls,
  })
  const contractRolls = _.orderBy(data, ["roll_date"])
  return (
    <View>
      <table className="data-table">
        <thead>
          <tr>
            <th>Symbol</th>
            <th style={{ textAlign: "left" }}>Name</th>
            <th>Roll Date</th>
          </tr>
        </thead>
        <tbody>
          {contractRolls.map((cr, inx) => (
            <tr key={inx}>
              <td style={{ paddingRight: 10 }}>{cr.symbol}</td>
              <td style={{ paddingRight: 10, textAlign: "left" }}>{cr.name}</td>
              <td>{cr.roll_date}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </View>
  )
}

export default ContractRollsPage
