export const getPortfolio = /* GraphQL */ `
  query GetPortfolio($id: ID!) {
    getPortfolio(id: $id) {
      id
      name
      groupsCanAccess
      slug
      status
      displayName
      maintenanceMargin

      historicalPnl {
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
        }
      }
      leverage
      contractRolls {
        symbol
        name
        roll_date
        roll_days
      }
      milestones {
        title
        date
      }
      isNoDollar
      isLegacy
      summaryType
      shouldAutoUpdate
      visibleToInvestors
      leverageToDisplay
      createdAt
      updatedAt
    }
  }
`

export const listPortfolios = /* GraphQL */ `
  query ListPortfolios(
    $filter: ModelPortfolioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        groupsCanAccess
        slug
        status
        displayName
        maintenanceMargin
        isNoDollar
        historicalPnl {
          formats
          summary {
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
          }
        }
        leverage
        contractRolls {
          symbol
          name
          roll_date
          roll_days
        }
        isLegacy
        summaryType
        shouldAutoUpdate
        visibleToInvestors
        leverageToDisplay
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const portfolioBySlug = /* GraphQL */ `
  query PortfolioBySlug(
    $slug: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPortfolioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    portfolioBySlug(
      slug: $slug
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        groupsCanAccess
        slug
        status
        displayName
        maintenanceMargin

        historicalPnl {
          formats
          summary {
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
          }
        }
        leverage
        contractRolls {
          symbol
          name
          roll_date
          roll_days
        }
        milestones {
          title
          date
        }
        isNoDollar
        isLegacy
        summaryType
        shouldAutoUpdate
        visibleToInvestors
        leverageToDisplay
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`

export const onCreatePortfolio = /* GraphQL */ `
  subscription OnCreatePortfolio {
    onCreatePortfolio {
      id
      name
      groupsCanAccess
      slug
      status
      displayName
      maintenanceMargin
      isNoDollar
      historicalPnl {
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
        }
      }
      leverage
      contractRolls {
        symbol
        name
        roll_date
        roll_days
      }
      isLegacy
      summaryType
      shouldAutoUpdate
      visibleToInvestors
      leverageToDisplay
      createdAt
      updatedAt
    }
  }
`
export const onUpdatePortfolio = /* GraphQL */ `
  subscription OnUpdatePortfolio {
    onUpdatePortfolio {
      id
      name
      groupsCanAccess
      slug
      status
      displayName
      maintenanceMargin
      isNoDollar
      historicalPnl {
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
        }
      }
      leverage
      contractRolls {
        symbol
        name
        roll_date
        roll_days
      }
      isLegacy
      summaryType
      shouldAutoUpdate
      visibleToInvestors
      leverageToDisplay
      createdAt
      updatedAt
    }
  }
`
export const onDeletePortfolio = /* GraphQL */ `
  subscription OnDeletePortfolio {
    onDeletePortfolio {
      id
      name
      groupsCanAccess
      slug
      status
      displayName
      maintenanceMargin
      isNoDollar
      historicalPnl {
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
        }
      }
      leverage
      contractRolls {
        symbol
        name
        roll_date
        roll_days
      }
      isLegacy
      summaryType
      shouldAutoUpdate
      visibleToInvestors
      leverageToDisplay
      createdAt
      updatedAt
    }
  }
`
export const mergedPortfolioChartData = /* GraphQL */ `
  query MergedPortfolioChartData(
    $portSlug: String!
    $range: String
    $benchmarkSlug: String
  ) {
    mergedPortfolioChartData(
      portSlug: $portSlug
      range: $range
      benchmarkSlug: $benchmarkSlug
    ) {
      id
      name
      displayName
      benchmarkDisplayName
      benchmarkSlug
      dataFrequency
      portfolioNames
      portfolioSlugs
      dateOptions
      selectedRange
      slug
      status
      lastSync
      chartData {
        period
        official
        deposit
        portfolio_value
        daily_return
        cumulative_return
        daily_return_pct
        benchmark_cumulative_return_pct
        cumulative_return_pct
        leverage
        leverage_cash
        realized_leverage
        return_cash
        cumulative_return_cash
        margin_initial
        margin_maintenance
        pct_drawdown
        drawdown_days
        time_to_recovery
        rebased_benchmark_return
        rebased_return
        rebased
        rebased_lev
        abs_return
        meta
      }
      metaData {
        todaysPNL
        monthToDate
        cumulativeReturnCash
        exposure
        cashLevel
        leverageSetting
        marginMaintenance
        marginMaintenancePortfolioValue
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_number_of_pnl_days
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
      }
      milestones {
        title
        date
      }
      isNoDollar
    }
  }
`
