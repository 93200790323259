import { API, graphqlOperation } from "aws-amplify"

import {
  listPortfolios,
  portfolioBySlug,
  mergedPortfolioChartData,
} from "./portfolio_graphql"

const getAllPortfolios = async () => {
  let ports = []
  async function fetchPorts(nextToken) {
    try {
      const { data } = await API.graphql(
        graphqlOperation(listPortfolios, {
          nextToken,
        })
      )
      ports = [...ports, ...data.listPortfolios.items]

      if (data.listPortfolios.nextToken) {
        await fetchPorts(data.listPortfolios.nextToken)
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  await fetchPorts()
  return ports
}

const REFRESH_RATE = 1000 * 60 * 3
export const portfoliosQuery = () => ({
  queryKey: ["portfolios"],
  queryFn: getAllPortfolios,
  refetchInterval: REFRESH_RATE,
})
export const portfolioQuery = (portfolioSlug) => ({
  queryKey: ["portfolios", portfolioSlug],
  queryFn: async () =>
    await API.graphql(
      graphqlOperation(portfolioBySlug, { slug: portfolioSlug })
    ).then((d) => d.data.portfolioBySlug.items[0]),
  refetchInterval: REFRESH_RATE,
})

export const pnlQuery = (portSlug, range, benchmarkSlug) => ({
  queryKey: ["portfolios", portSlug, range, benchmarkSlug],
  queryFn: async () =>
    await API.graphql(
      graphqlOperation(mergedPortfolioChartData, {
        portSlug,
        range,
        benchmarkSlug,
      })
    ).then((d) => d.data.mergedPortfolioChartData),
  refetchInterval: REFRESH_RATE,
})
