import { useTheme } from "@aws-amplify/ui-react"
import _ from "lodash"
import React, { useContext } from "react"
import { NavLink, useSearchParams } from "react-router-dom"
import { AuthContext } from "../../components/AmplifyAuth/AuthContext"
import { SubMenu, MenuItem, menuClasses } from "react-pro-sidebar"

export const usePortfolioURL = () => {
  const { userMeta } = useContext(AuthContext)
  const [searchParams] = useSearchParams()
  const range = userMeta.showRanges
    ? searchParams.get("r") ||
      localStorage.getItem("selectedRange")?.slice(1, -1)
    : null
  const benchmark = userMeta.spOverlay ? searchParams.get("benchmark") : null

  return {
    range,
    benchmark,
    queryString: `?r=${range}${benchmark ? "&benchmark=" + benchmark : ""}`,
  }
}

const PortfolioMenuList = ({ portfolios }) => {
  const portfolioTypes = _.groupBy(portfolios, (p) => p.status)
  const { tokens } = useTheme()
  const visibleTypes = ["PRODUCTION", "MODEL"]
  const { queryString } = usePortfolioURL()
  return visibleTypes.map((portType) => {
    const ports = portfolioTypes[portType]

    return (
      !!ports?.length && (
        <SubMenu
          defaultOpen
          key={portType}
          label={portType}
          rootStyles={{
            ["." + menuClasses.subMenuContent]: {
              backgroundColor: tokens.colors.background.primary.value,
              fontSize: "0.9em",
            },
            ["." + menuClasses.button]: {
              height: "1.2rem",
            },
          }}
        >
          {_.chain(ports)
            .orderBy(["name"], ["asc"])
            .map((port, inx) => (
              <MenuItem
                key={`${portType}-${port.id}-${inx}`}
                component={
                  <NavLink
                    to={`/portfolios/${port.slug}${queryString}`}
                    style={({ isActive }) => ({
                      fontWeight: isActive ? "bold" : "normal",
                    })}
                  />
                }
              >
                {port.displayName}
              </MenuItem>
            ))
            .value()}
        </SubMenu>
      )
    )
  })
}

export default PortfolioMenuList
