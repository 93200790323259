import moment from "moment"

const chart_config = {
  chart_margin: {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  xAxisPadding: { left: 20, right: 20 },
  xAxisTick: (tick) => `${moment(tick, "YYYY-MM-DD").format("M-DD")}`,
  yTickFormatter: (tick) => (tick > 0 ? `${tick.toFixed(2)}` : ""),
  percTickFormatter: (tick) =>
    Math.abs(tick) >= 1 ? `${tick.toFixed(2)}%` : "",
  legendWrapperStyle: { top: -15 },
  chartHeight: 300,
}

export default chart_config
