import { useSearchParams } from "react-router-dom"
import { useLocalStorage } from "../../../../../lib/useLocalStorage"
import { useEffect } from "react"

const usePNLRange = () => {
  const [selectedRange, setSelectedRange] = useLocalStorage(
    "selectedRange",
    "ALL"
  )
  const [params, setSearchParams] = useSearchParams()
  let range = params.get("r")

  useEffect(() => {
    params.set("r", selectedRange)
    setSearchParams(params)
  }, [params, selectedRange, setSearchParams])

  const handleChange = (dateOpt) => {
    setSelectedRange(dateOpt)
  }

  return {
    handleChange,
    range,
  }
}

export default usePNLRange
