import React from "react"
import ReactDOM from "react-dom/client"
import "@aws-amplify/ui-react/styles.css"
import "./index.css"
import App from "./App"
// import reportWebVitals from "./reportWebVitals"
import { Amplify } from "aws-amplify"
import config from "./config"
import { ThemeProvider } from "@aws-amplify/ui-react"
import { metagoraTheme } from "./theme"
import { AuthProvider } from "./components/AmplifyAuth/AuthContext"
import ReactGA from "react-ga4"
const TRACKING_ID = process.env.REACT_APP_GA_ID
// const TRACKING_ID = "UA-173427740-2"
ReactGA.initialize(TRACKING_ID)
// OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID)

Amplify.configure(config)

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <AuthProvider>
    <React.StrictMode>
      <ThemeProvider theme={metagoraTheme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </AuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
