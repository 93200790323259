import {
  Button,
  Card,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
  useTheme,
} from "@aws-amplify/ui-react"
import React from "react"
import { NavLink } from "react-router-dom"
import _ from "lodash"
import RespText from "../../components/RespText"
import { decimal_format } from "./lib/utils"
import { usePortfolioURL } from "./PortfolioList"
import AuthHOC from "../../components/AmplifyAuth/AuthHOC"
import { useQuery } from "@tanstack/react-query"
import { portfoliosQuery } from "./lib/usePortfolios"
import { useProSidebar } from "react-pro-sidebar"
import { GiHamburgerMenu } from "react-icons/gi"
import LoadingFull from "../../components/LoadingFull"

const PortfolioCard = ({ portfolio }) => {
  const { displayName, slug, maintenanceMargin } = portfolio
  const { summary } = portfolio.historicalPnl || {}
  const { tokens } = useTheme()

  const valueColor = tokens.colors.metagora.value_color.value

  const { queryString } = usePortfolioURL()

  return (
    <Card
      flex={1}
      fontSize="1em"
      minWidth={{ base: "100%", medium: "48%", large: "31%" }}
      fontWeight="bold"
    >
      <Heading level={5}>
        <Link
          as={NavLink}
          to={`${slug}${queryString}`}
          color={tokens.colors.font.primary.value}
          style={{
            whiteSpace: "nowrap",
            fontSize: "0.8em",
          }}
        >
          {displayName}
        </Link>
      </Heading>
      <Divider />
      {!!summary && (
        <Flex
          direction="column"
          gap={1}
          fontSize={{ base: "1.1em", medium: "1em" }}
        >
          <Flex justifyContent="space-between">
            <RespText computer="Total Cumulative Return" mobile="TCR" />{" "}
            <Text color={valueColor(summary?.total_cumulative_return_pct)}>
              {decimal_format(summary?.total_cumulative_return_pct)}%
            </Text>
          </Flex>
          {!!summary?.avg_daily_return_pct && (
            <Flex justifyContent="space-between">
              <RespText computer="Avg Trading Day Return" mobile="ATDR" />{" "}
              <Text color={valueColor(summary?.avg_daily_return_pct)}>
                {decimal_format(summary?.avg_daily_return_pct)}%
              </Text>
            </Flex>
          )}
          <Flex justifyContent="space-between">
            <RespText computer="Avg Calendar Day Return" mobile="ACDR" />{" "}
            <Text color={valueColor(summary?.avg_calendar_daily_return_pct)}>
              {decimal_format(summary?.avg_calendar_daily_return_pct)}%
            </Text>
          </Flex>

          {!!maintenanceMargin && (
            <AuthHOC group="maintenanceMargin">
              <Flex justifyContent="space-between">
                <RespText computer="Maintenance Margin" mobile="MM" />{" "}
                <Text color={tokens.colors.font.secondary.value}>
                  {decimal_format(maintenanceMargin)}%
                </Text>
              </Flex>
            </AuthHOC>
          )}
        </Flex>
      )}
    </Card>
  )
}

const PortfolioTable = () => {
  const { toggleSidebar } = useProSidebar()
  const { data: portfolios, isLoading } = useQuery(portfoliosQuery())
  const visibleTypes = ["PRODUCTION", "MODEL"]
  if (isLoading) return <LoadingFull />
  return (
    <Flex direction="column" alignItems="flex-start">
      <Button onClick={() => toggleSidebar()}>
        <GiHamburgerMenu />
      </Button>
      <Flex direction="column">
        {visibleTypes.map((portType) => {
          const ports = _.orderBy(
            portfolios.filter((p) => p.status === portType),
            ["name"],
            ["asc"]
          )
          return (
            !!ports?.length && (
              <Flex direction="column" key={portType}>
                <Heading level={4}>{portType}</Heading>
                <Flex direction="row" wrap="wrap">
                  {ports.map((port) => (
                    <PortfolioCard key={port.id} portfolio={port} />
                  ))}
                </Flex>
              </Flex>
            )
          )
        })}
      </Flex>
    </Flex>
  )
}

export default PortfolioTable
