import React from "react"
import { Authenticator } from "@aws-amplify/ui-react"
import components from "./auth_components"
import getUserInfo from "./userMeta"
import { useLocalStorage } from "../../lib/useLocalStorage"

let AuthContext
const { Provider, Consumer } = (AuthContext = React.createContext())

const AuthProvider = ({ children }) => {
  const [adminOverlay, setAdminOverlay] = useLocalStorage(
    "admin-overlays",
    "hide"
  )
  return (
    <Authenticator components={components}>
      {({ signOut, user }) => {
        const signOutFn = async () => {
          await signOut()
          localStorage.clear()
        }
        if (!user) return null
        const userMeta = getUserInfo(user)
        return (
          <Provider
            value={{
              signOut: signOutFn,
              user,
              userMeta,
              showAdminOverlay: adminOverlay === "show",
              setAdminOverlay,
            }}
          >
            {children}
          </Provider>
        )
      }}
    </Authenticator>
  )
}

export { AuthContext, Consumer as AuthConsumer, AuthProvider }
