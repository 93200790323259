import moment from "moment"

export const removeItemfromArray = (array, item) => {
  let inx = array.indexOf(item)
  return [...array.slice(0, inx), ...array.slice(inx + 1)]
}
const GREEN_COLOR = "#00FF00"
const RED_COLOR = "#FF0000"
export const colorNum = (num) => (num >= 0 ? GREEN_COLOR : RED_COLOR)

/**
 * Return a formatted date
 *
 * @param {string} date
 * @param {string} dateFormat default "DD-MMM-YY"
 * @param {string} initialFormat default 'YYYY-MM-DD'
 * @returns {string}
 */

export const formatDate = (
  date,
  dateFormat = "DD-MMM-YY",
  initialFormat = "YYYY-MM-DD"
) => moment(date, initialFormat).format(dateFormat)

/**
 * Return a function
 *
 * @param {float} baseline base leverage
 * @returns {Function}
 */

/**
 * Return position of current leverage on a scale based on the base leverage (baseline)
 *
 * @param {float} value leverage
 * @returns {int} [0-9]
 */

const calculateLeverageColors = (baseline) => (value) => {
  if (value >= baseline + 2) {
    return 9 // red_dark
  } else if (value > baseline + 1.4 && value < baseline + 2) {
    return 8 // red
  } else if (value > baseline + 1.0 && value <= baseline + 1.4) {
    return 7 // orange
  } else if (value > baseline + 0.6 && value <= baseline + 1.0) {
    return 6 // yellow
  } else if (value > baseline + 0.1 && value <= baseline + 0.6) {
    return 5 // olive
  } else if (value >= baseline - 0.1 && value <= baseline + 0.1) {
    return 4 // green
  } else if (value >= baseline - 0.25 && value < baseline - 0.1) {
    return 3 // bluegreen
  } else if (value >= baseline - 0.5 && value < baseline - 0.25) {
    return 2 // light blue
  } else if (value >= baseline - 1 && value < baseline - 0.5) {
    return 1 // blue
  } else if (value < baseline - 1) {
    return 0 // deep blue
  } else {
    return 0
  }
}

/**
 * Return a linear-gradient string that creates a colored bar at a percentage with css
 *
 * @param {string} color
 * @param {float} perc
 * @returns {string}
 */

const cssBar = (color, perc) =>
  `linear-gradient(-90deg, rgba(${color.join(",")}, 0.25) 0%, rgba(${color.join(
    ","
  )}, 0.15) ${perc}%, rgba(0,0,0,0) ${perc}%, rgba(0,0,0,0) 100%)`

const LEVERAGE_COLORS = {
  0: [0, 4, 255], //deep blue
  1: [0, 89, 255], //blue
  2: [0, 204, 255], //light blue
  3: [0, 255, 213], //bluegreen
  4: [0, 255, 21], //green
  5: [157, 255, 0], //olive
  6: [255, 238, 0], //yellow
  7: [255, 145, 0], //orange
  8: [255, 51, 0], //red
  9: [167, 2, 2], //red_dark
}

/**
 * Return a style object that will display the leverage bar
 *
 * @param {int} leverage
 * @param {int} baseLev
 * @param {int} divider
 * @returns {Object}
 */

export const pnlRowStyle = (leverage, baseLev, divider) => {
  const leverageRanking = calculateLeverageColors(baseLev)
  const perc = ((leverage / divider) * 100).toFixed()
  const lvg_color = LEVERAGE_COLORS[leverageRanking(leverage)]
  return {
    backgroundImage: cssBar(lvg_color, perc),
    backgroundPosition: "right",
  }
}

/**
 * Return a string of an abbreviated large number
 *
 * @param {Number} num a number
 * @param {int} fixed (Optional) number of decimal places to show
 * @returns {string}
 */

export const abbreviate_number = function (num, fixed) {
  if (num === null) {
    return null
  } // terminate early
  if (num === 0) {
    return "0"
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed // number of decimal places to show
  var b = num.toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ["", "K", "M", "B", "T"][k] // append power
  return e
}
