import moment from "moment"

const DateFormat = ({
  date,
  format = "DD-MMM h:mm a Z",
  initialFormat = "YYYY-MM-DD",
}) =>
  moment(date).isValid() ? moment(date, initialFormat).format(format) : null

export default DateFormat
