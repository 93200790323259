import { Flex, Loader } from "@aws-amplify/ui-react"
import React from "react"

const LoadingFull = () => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <Loader size="large" />
    </Flex>
  )
}

export default LoadingFull
