import { Button, Flex, Heading, useTheme, View } from "@aws-amplify/ui-react"
import React from "react"
import {
  Await,
  NavLink,
  Outlet,
  useAsyncValue,
  useParams,
} from "react-router-dom"
import DateFormat from "../../components/DateFormat"
import { Suspense } from "react"
import { usePortfolioURL } from "./PortfolioList"
import AuthHOC from "../../components/AmplifyAuth/AuthHOC"
import { useQuery } from "@tanstack/react-query"
import { portfolioQuery } from "./lib/usePortfolios"
import { useProSidebar } from "react-pro-sidebar"
import { GiHamburgerMenu } from "react-icons/gi"
import LoadingFull from "../../components/LoadingFull"
const PortfolioDetailNav = () => {
  const { tokens } = useTheme()
  const { queryString } = usePortfolioURL()
  return (
    <Flex>
      <Button
        as={NavLink}
        variation="link"
        end={true}
        to={`.${queryString}`}
        style={({ isActive }) => ({
          color: isActive
            ? tokens.colors.neutral[20]
            : tokens.colors.neutral[60],
          textDecoration: isActive ? "underline" : undefined,
        })}
      >
        Historical PNL
      </Button>
      <Button
        as={NavLink}
        variation="link"
        to="contractrolls"
        style={({ isActive }) => ({
          color: isActive
            ? tokens.colors.neutral[20]
            : tokens.colors.neutral[60],
          textDecoration: isActive ? "underline" : undefined,
        })}
      >
        Contract Rolls
      </Button>
    </Flex>
  )
}
const PortfolioDetailHeader = () => {
  const { tokens } = useTheme()
  const { toggleSidebar } = useProSidebar()
  const portfolio = useAsyncValue()
  return (
    <Flex
      justifyContent="space-between"
      direction={{ base: "column", medium: "row" }}
    >
      <Flex alignItems="center">
        <Button onClick={() => toggleSidebar()}>
          <GiHamburgerMenu />
        </Button>
        <Flex
          direction={{ base: "column", medium: "row" }}
          alignItems="flex-start"
        >
          <Flex direction="column" gap={1}>
            <Heading
              level={3}
              fontSize={{
                base: "1.3em",
                medium: "2em",
                computer: "2em",
              }}
              whiteSpace="nowrap"
            >
              {portfolio.displayName}
            </Heading>
            {portfolio.contractRolls && (
              <AuthHOC group="contractRolls">
                <PortfolioDetailNav />
              </AuthHOC>
            )}
          </Flex>
        </Flex>
      </Flex>
      <View
        fontSize={{ base: "0.6em", medium: "0.8em" }}
        color={tokens.colors.font.muted.value}
        style={{ whiteSpace: "nowrap" }}
      >
        Updated: <DateFormat date={portfolio.updatedAt} initialFormat={null} />
      </View>
    </Flex>
  )
}

const PortfolioDetailLayout = () => {
  // const portfolio = useLoaderData()
  const { portfolioSlug } = useParams()
  const { data: portfolio, isLoading } = useQuery(portfolioQuery(portfolioSlug))

  if (isLoading) return <LoadingFull />

  return (
    <Flex direction="column">
      <Suspense fallback={<LoadingFull />}>
        <Await resolve={portfolio}>
          <PortfolioDetailHeader />
        </Await>
      </Suspense>

      <Outlet />
    </Flex>
  )
}

export default PortfolioDetailLayout
