import { createTheme } from "@aws-amplify/ui-react"

const RED_COLOR = "rgba(255, 0, 0, 0.7)"
const RED_NUMBERS = "rgba(255, 80, 0, .9)"
// const RED_NUMBERS = "red"

const GREEN_NUMBERS = "rgba(4, 255, 4, .9)"
// const GREEN_COLOR = "rgba(2, 173, 2, 0.5)"
const GREEN_COLOR = GREEN_NUMBERS
const DARK_GREEN_COLOR = "rgba(4, 255, 4, 1)"

const valueColor = (num) => {
  const just_the_num = /(-?[0-9.]*)/g.exec(num)[0]
  const v = just_the_num === "" ? num : Number(just_the_num)
  return typeof v == "number"
    ? v !== 0
      ? v >= 0
        ? GREEN_NUMBERS
        : RED_NUMBERS
      : DARK_GREEN_COLOR
    : "rgba(255,255,255,0.4)"
}

function hexToRgbA(hex, alpha = 1) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      alpha +
      ")"
    )
  }
  throw new Error("Bad Hex")
}

export const metagoraTheme = createTheme({
  name: "metagora-theme",
  tokens: {
    fonts: {
      default: {
        variable: { value: "Lato, sans-serif" },
        static: { value: "Lato, sans-serif" },
      },
    },
    components: {
      link: {
        focus: {
          color: { value: "{colors.neutral.40}" },
        },
        hover: {
          color: { value: "{colors.neutral.60}" },
          backgroundColor: { value: "{colors.blue.80}" },
        },
        visited: {
          color: { value: "{colors.neutral.20}" },
        },
      },

      button: {
        // this will affect the font weight of all button variants
        // fontWeight: { value: "{fontWeights.extrabold}" },
        // style the primary variation
        link: {
          fontSize: { value: "0.8em" },
          backgroundColor: { value: "{colors.black}" },
          color: { value: "{colors.neutral.40}" },
          _hover: {
            backgroundColor: { value: "{colors.blue.80}" },
            color: { value: "{colors.neutral.40}" },
          },
          _focus: {
            backgroundColor: { value: "{colors.blue.80}" },
            color: { value: "{colors.neutral.40}" },
          },
          _active: {
            backgroundColor: { value: "{colors.blue.90}" },
            color: { value: "{colors.neutral.40}" },
          },
        },
      },
    },
    space: {
      large: 100,
    },
    colors: {
      font: {
        primary: {
          value: "rgba(218, 216, 216, .9)",
        },
        secondary: {
          value: "rgba(246, 246, 246, 0.9)",
        },
        muted: {
          value: "rgba(246, 246, 246, 0.7)",
        },
      },
      metagora: {
        value_color: {
          value: valueColor,
        },
        hex_to_rgba: {
          value: hexToRgbA,
        },
        white: {
          value: "rgba(255,255,255,0.7)",
        },
        green: {
          value: GREEN_COLOR,
        },
        green_chart: {
          value: "rgba(2, 173, 2, .8)",
        },
        red: {
          value: RED_COLOR,
        },
        chart_tick: {
          value: "{colors.neutral[80]}",
        },
        benchmark_chart: {
          value: "rgba(99, 4, 125, .6) ",
        },
        benchmark_chart_stroke: {
          value: "rgba(255, 0, 255, .7) ",
        },
        unofficial_blue: {
          value: "{colors.blue[80]}",
        },
        unofficial_blue_chart: {
          value: "{colors.blue[60]}",
          // value: "rgba(220, 95, 30, 1)",
        },
        leverage: {
          value: "rgb(242, 113, 28)",
        },
        target_leverage: {
          value: "rgba(65, 170, 255, 0.8)",
        },
        cumulative: {
          value: "rgb(90, 126, 167)",
        },
        ann_slippage: {
          value: "rgb(85, 214, 231)",
        },
        ann_commission: {
          value: "rgb(0, 136, 254)",
        },
        PRODUCTION: {
          value: "rgba(154, 152, 1, 0.8)",
        },
        EXPERIMENTAL: {
          value: "rgba(36, 124, 168, 0.8)",
        },
        LEGACY: {
          value: "rgba(84, 1, 129, 0.8)",
        },
        DRAFT: {
          value: "rgba(108, 108, 108, 0.8)",
        },
      },
      background: {
        primary: {
          value: "rgba(26, 25, 25, 0.9)",
        },
        secondary: {
          value: "rgba(26, 25, 25, 0.4)",
        },
        historical: {
          value: "#013601",
        },
        dashboard: {
          value: "{colors.blue[90]}",
        },
        slippage: {
          value: "{colors.orange[90]}",
        },
        auto_reconciliation: {
          value: "{colors.teal[90]}",
        },
        settings: {
          value: "#3b3b3b",
        },
      },
    },
  },
})
