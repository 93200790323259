import React from "react"
import { Menu, MenuButton, MenuItem } from "@aws-amplify/ui-react"

const date_opts = {
  ALL: {
    label: "All",
  },
  MTD: {
    label: "Month To Date",
  },
  YTD: {
    label: "Year To Date",
  },
  LM: {
    label: "Last Month",
  },
  L3M: {
    label: "Last 3 Months",
  },
  L6M: {
    label: "Last 6 Months",
  },
  L12M: {
    label: "Last 12 Months",
  },
  L15M: {
    label: "Last 15 Months",
  },
  L18M: {
    label: "Last 18 Months",
  },
}

const PNLDateMenu = ({ dateOptions, range, handleChange }) => {
  return (
    !!date_opts[range] && (
      <Menu
        trigger={
          <MenuButton size="small" variation="link">
            {date_opts[range].label}
          </MenuButton>
        }
      >
        {dateOptions?.map((dateOpt) => (
          <MenuItem
            key={dateOpt}
            value={dateOpt}
            isDisabled={range === dateOpt}
            onClick={() => handleChange(dateOpt)}
          >
            {date_opts[dateOpt].label}
          </MenuItem>
        ))}
      </Menu>
    )
  )
}

export default PNLDateMenu
