import React from "react"
import {
  ResponsiveContainer,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  Cell,
  ReferenceLine,
  Legend,
  Area,
} from "recharts"
import CreateCustomPNLTooltip from "./CreateCustomPNLTooltip"
import { Flex, Text, useTheme } from "@aws-amplify/ui-react"
import chart_config from "./chart_config"
import moment from "moment"

const renderLegend =
  (benchmarkDisplayName) =>
  ({ payload }) => {
    return (
      <Flex justifyContent="center" paddingBottom={25}>
        {payload.map((entry, index) => {
          return (
            <Text
              color={entry.color}
              fontWeight="bold"
              key={`item-${index}`}
              fontSize=".8em"
            >
              {entry.dataKey.startsWith("rebased_return")
                ? entry.dataKey === "rebased_return"
                  ? "Cumulative Return"
                  : "Cumulative Return Cash"
                : entry.dataKey.startsWith("rebased_benchmark")
                ? benchmarkDisplayName
                : entry.value
                    .split("_")
                    .map((v) => `${v[0].toUpperCase()}${v.substring(1)}`)
                    .join(" ")}
            </Text>
          )
        })}
      </Flex>
    )
  }

const HistoricalPNLChart = ({
  showTooltip,
  data = [],
  leverage = 1.0,
  leverageToDisplay,
  isWeekly,
  showBenchmark,
  benchmarkDisplayName,
  milestones = [],
  showLeverage = true,
  showDollar = true,
}) => {
  const { tokens } = useTheme()
  const CustomTooltip = CreateCustomPNLTooltip(
    data,
    leverage,
    leverageToDisplay,
    isWeekly,
    benchmarkDisplayName,
    showLeverage,
    showDollar
  )
  // console.log(data, milestones)
  const leverageField =
    leverageToDisplay === "REALIZED_LEVERAGE"
      ? "realized_leverage"
      : leverageToDisplay === "LEVERAGE_CASH"
      ? "leverage_cash"
      : "leverage"

  const refLineLeftLabel = {
    value: "0.0",
    fill: tokens.colors.metagora.leverage.value,
    position: "left",
  }

  const refLineRightLabel = {
    value: "0%",
    fill: tokens.colors.metagora.white.value,
    position: "right",
  }

  const leverageRefLine = {
    value: `Target Leverage ${leverage}`,
    fill: tokens.colors.metagora.target_leverage.value,
    position: "top",
    hide: showBenchmark,
  }

  const barCell = (d, i) => (
    <Cell
      key={i}
      fill={
        d.official
          ? d.cumulative_return_pct > 0
            ? tokens.colors.metagora.green_chart.value
            : tokens.colors.metagora.red.value
          : tokens.colors.metagora.unofficial_blue.value
      }
    />
  )
  return (
    <ResponsiveContainer height={chart_config.chartHeight} debounce={50}>
      <ComposedChart
        data={data}
        margin={chart_config.chart_margin}
        syncId="metagora"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          hide={!showTooltip}
          dataKey="period"
          tick={{ stroke: tokens.colors.metagora.chart_tick.value }}
          tickFormatter={chart_config.xAxisTick}
          padding={chart_config.xAxisPadding}
          fontSize="0.8em"
        />
        <YAxis
          hide={!showTooltip}
          yAxisId="left"
          stroke={tokens.colors.metagora.leverage.value}
          domain={[0, "dataMax + 0.1"]}
          tickFormatter={chart_config.yTickFormatter}
          fontSize="0.8em"
        />
        <YAxis
          hide={!showTooltip}
          yAxisId="right"
          orientation="right"
          tick={{ stroke: tokens.colors.metagora.chart_tick.value }}
          tickFormatter={chart_config.percTickFormatter}
          domain={["dataMin - 0.1", "dataMax + 0.1"]}
          fontSize="0.8em"
        />
        <Legend
          hide={!showTooltip}
          verticalAlign="top"
          content={renderLegend(benchmarkDisplayName)}
        />
        {showTooltip && <Tooltip content={<CustomTooltip />} />}

        <ReferenceLine
          isFront={true}
          yAxisId="right"
          y={0}
          stroke={tokens.colors.metagora.white.value}
          label={refLineRightLabel}
          strokeDasharray="3 3"
        />
        <ReferenceLine
          isFront={true}
          yAxisId="left"
          y={0}
          stroke={tokens.colors.metagora.white.value}
          label={refLineLeftLabel}
          strokeDasharray="3 3"
        />

        <Bar yAxisId="right" dataKey="cumulative_return_pct">
          {data?.map(barCell)}
        </Bar>

        {showLeverage && (
          <ReferenceLine
            isFront={true}
            yAxisId="left"
            y={leverage}
            label={leverageRefLine}
            stroke={tokens.colors.metagora.target_leverage.value}
          />
        )}

        {!showBenchmark
          ? showLeverage && (
              <Line
                yAxisId="left"
                type="monotone"
                dataKey={leverageField}
                stroke={tokens.colors.metagora.leverage.value}
                strokeWidth={3}
                dot={false}
              />
            )
          : isWeekly && (
              <Line
                yAxisId="left"
                type="monotone"
                dataKey={leverageField}
                stroke={tokens.colors.metagora.leverage.value}
                strokeWidth={3}
                dot={false}
              />
            )}
        {!!showBenchmark ? (
          <Area
            yAxisId="right"
            type="monotone"
            dataKey="rebased_benchmark_return"
            strokeWidth={3}
            fill={tokens.colors.metagora.benchmark_chart}
            stroke={tokens.colors.metagora.benchmark_chart_stroke}
          />
        ) : (
          isWeekly && (
            <Area
              yAxisId="right"
              type="monotone"
              dataKey="rebased_benchmark_return"
              strokeWidth={3}
              fill={tokens.colors.metagora.benchmark_chart}
              stroke={tokens.colors.metagora.benchmark_chart_stroke}
            />
          )
        )}
        {milestones.map((milestone, i) => (
          <ReferenceLine
            key={i}
            isFront={true}
            x={moment(milestone.date).format("YYYY-MM-DD")}
            stroke="#70baff"
            label={{
              value: milestone.title,
              offset: 5,
              position: "insideTopRight",
              stroke: "#70baff",
            }}
            yAxisId="left"
            strokeOpacity={1}
            strokeWidth={3}
            ifOverflow="discard"
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default HistoricalPNLChart
