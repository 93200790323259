const checkGroups = (signInUserSession) => {
  const groups = signInUserSession
    ? signInUserSession.accessToken.payload["cognito:groups"]
    : []
  const inGroup = (name) => (!!groups ? groups.includes(name) : !!groups)
  return {
    groups,
    isAdmin: inGroup("Admins"), // Marockly, Ed, Alex, Michael
    isQuant: inGroup("Quant"),
    isInvestor: inGroup("Investors"),
    showVis: inGroup("QuantVisuals"), // 23 members
    dailyModelSummary: inGroup("DailyModelSummary"), // no one in this group
    showRanges: inGroup("QuantRanges"), // 22 members
    showSorting: inGroup("QuantSorting"), // 6 members - Adam
    contractRolls: inGroup("ContractRolls"), // 4 members in group
    maintenanceMargin: inGroup("MaintenanceMargin"), // 2 members Falk - Cabot
    spOverlay: inGroup("SANDPOverlay"), // 7 members
    depositWithdraw: inGroup("DepositWithdraw"), // 7 members
  }
}

const getUserInfo = (user) => {
  return {
    ...checkGroups(user.signInUserSession),
    username: user?.username,
    ...user?.attributes,
  }
}

export default getUserInfo
