import { Text, useTheme } from "@aws-amplify/ui-react"
import React from "react"
const wrapperStyle = { position: "relative" }

const ValueChangeIcon = ({
  value,
  formatFn,
  disable_value_color,
  hide_icon,
  ...rest
}) => {
  const { tokens } = useTheme()

  const value_color = disable_value_color
    ? tokens.colors.font.primary.value
    : tokens.colors.metagora.value_color.value(value)

  const formattedValue = formatFn(value)

  return (
    <Text as="span" color={value_color} style={wrapperStyle} {...rest}>
      {formattedValue}
    </Text>
  )
}

export default ValueChangeIcon
