import {
  Card,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  Text,
  View,
} from "@aws-amplify/ui-react"
import React, { useContext, useMemo, useEffect } from "react"
import {
  isRouteErrorResponse,
  useParams,
  useRouteError,
  useSearchParams,
} from "react-router-dom"
import pnl_data_config from "./historical_pnl_config"
import HistoricalPNL from "./HistoricalPNL"
import { AuthContext } from "../../../../components/AmplifyAuth/AuthContext"
import HistoricalPNLChart from "./charts/HistoricalPNLChart"
import { useLocalStorage } from "../../../../lib/useLocalStorage"
import { useState } from "react"
import _ from "lodash"
import PNLDateMenu from "./PNLDateMenu"
import AuthHOC from "../../../../components/AmplifyAuth/AuthHOC"
import { useQuery } from "@tanstack/react-query"
import { pnlQuery } from "../../lib/usePortfolios"
import { useProSidebar } from "react-pro-sidebar"
import LoadingFull from "../../../../components/LoadingFull"
import usePNLRange from "./hooks/usePNLRange"
import ToggleTooltip from "./components/ToggleTooltip"

export const PNLRouteErrorBoundary = () => {
  const error = useRouteError()
  if (isRouteErrorResponse(error) && error.status !== 501) {
    console.log(error)
    throw error
  }
  return (
    <Card>
      <Heading>PNL Error</Heading>
      <Flex>
        <pre>{JSON.stringify(error, true, 2)}</pre>
      </Flex>
    </Card>
  )
}

const usePNLData = (chartData) => {
  const [pnl_data, setChartData] = useState(chartData)

  useEffect(() => {
    setChartData(
      _.orderBy(
        chartData.filter((d) => !JSON.parse(d.meta)?.previous),
        ["period"],
        ["asc"]
      )
    )
  }, [chartData])

  return {
    data: pnl_data,
  }
}

const useBenchmarkParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  let benchmark_slug = searchParams.get("benchmark")
  const [b_slug, setBSlug] = useState(benchmark_slug)

  const handleBenchmarkChange = (benchmarkSlug) => {
    setBSlug(benchmarkSlug)
    if (benchmarkSlug === null) {
      searchParams.delete("benchmark")
    } else {
      searchParams.set("benchmark", benchmarkSlug)
    }

    setSearchParams(searchParams)
  }

  return {
    handleBenchmarkChange,
    b_slug,
  }
}

const columnKeyMap = {
  NoDollar: ["period", "daily_return_pct", "rebased", "leverage"],
  NoLeverage: [
    "period",
    "portfolio_value",
    "daily_return",
    "cumulative_return",
    "daily_return_pct",
    "rebased",
  ],
  defaultColumns: [
    "period",
    "portfolio_value",
    "daily_return",
    "cumulative_return",
    "daily_return_pct",
    "rebased",
    "leverage",
  ],
}

const HistoricalPNLPage = ({ portfolioPNL, isFetching }) => {
  const { userMeta } = useContext(AuthContext)
  const { data } = usePNLData(portfolioPNL.chartData)
  const {
    portfolioMeta: { leverage, leverageToDisplay },
    dataFrequency: summaryType,
    dateOptions,
    name,
    isNoDollar,
  } = portfolioPNL

  // console.log(isNoDollar)

  const IS_WEEKLY = summaryType === "WEEKLY"

  const { handleChange, range } = usePNLRange()

  const { handleBenchmarkChange, b_slug } = useBenchmarkParams()

  const [showTooltip, setShowToolTip] = useLocalStorage("showToolTip", "show")

  const is_coco = name === "Coco-15%"

  const config = useMemo(() => {
    const cols = pnl_data_config(leverageToDisplay, IS_WEEKLY)
    if (isNoDollar) {
      return cols.filter((c) => columnKeyMap.NoDollar.includes(c.key))
    }
    if (is_coco) {
      return cols.filter((c) => columnKeyMap.NoLeverage.includes(c.key))
    }
    return cols.filter((c) => columnKeyMap.defaultColumns.includes(c.key))
  }, [leverageToDisplay, IS_WEEKLY, is_coco, isNoDollar])

  return (
    <Flex direction="column">
      <Flex justifyContent="space-between">
        <AuthHOC group="showRanges">
          <PNLDateMenu
            dateOptions={dateOptions}
            handleChange={handleChange}
            range={range}
          />
        </AuthHOC>
        {isFetching && <Text>fetching...</Text>}
        <AuthHOC group="showVis">
          <>
            <Flex justifyContent="flex-end">
              {!IS_WEEKLY && (
                <AuthHOC group="spOverlay">
                  <Menu
                    trigger={
                      <MenuButton size="small" variation="link">
                        {portfolioPNL.benchmarkDisplayName ?? "Overlay"}
                      </MenuButton>
                    }
                  >
                    <MenuItem onClick={() => handleBenchmarkChange(null)}>
                      Clear
                    </MenuItem>

                    {name === "Coco-15%" ? (
                      <MenuItem
                        onClick={() =>
                          handleBenchmarkChange(
                            "Futures-Relative-Value-Prod-BFI"
                          )
                        }
                      >
                        BFI-FRV
                      </MenuItem>
                    ) : (
                      <MenuItem onClick={() => handleBenchmarkChange("SP500")}>
                        S&P500
                      </MenuItem>
                    )}
                  </Menu>
                </AuthHOC>
              )}
              <ToggleTooltip
                showTooltip={showTooltip === "show"}
                setShowToolTip={setShowToolTip}
              />
            </Flex>
          </>
        </AuthHOC>
      </Flex>
      <AuthHOC group="showVis">
        <HistoricalPNLChart
          showTooltip={showTooltip === "show"}
          data={data}
          isWeekly={IS_WEEKLY}
          summaryType={summaryType}
          leverage={leverage}
          leverageToDisplay={leverageToDisplay}
          showBenchmark={!!b_slug && userMeta.spOverlay}
          benchmarkDisplayName={portfolioPNL.benchmarkDisplayName}
          milestones={portfolioPNL.milestones}
          showLeverage={!is_coco}
          showDollar={!isNoDollar}
        />
      </AuthHOC>
      <View>
        <HistoricalPNL
          config={config}
          pnlData={data}
          isWeekly={IS_WEEKLY}
          sorting={userMeta.showSorting}
          leverageToDisplay={leverageToDisplay}
          portLeverage={leverage}
          showLeverage={!is_coco}
          noDollar={!isNoDollar}
        />
      </View>
    </Flex>
  )
}

const checkBenchmark = (benchmarkParam, portfolioSlug) => {
  if (benchmarkParam === "SP500" && portfolioSlug !== "Coco-15") {
    return "SP500"
  } else if (
    benchmarkParam === "Futures-Relative-Value-Prod-BFI" &&
    portfolioSlug === "Coco-15"
  ) {
    return "Futures-Relative-Value-Prod-BFI"
  } else {
    return null
  }
}

const HistoricalPage = () => {
  const { portfolioSlug } = useParams()
  const [searchParams] = useSearchParams()
  const range = searchParams.get("r")
  const benchmarkParam = searchParams.get("benchmark")
  // const benchmarkSlug = benchmarkParam === "SP500" ? "SP500" : null
  // const benchmarkSlug = !!benchmarkParam ? benchmarkParam : null
  const benchmarkSlug = checkBenchmark(benchmarkParam, portfolioSlug)
  const { toggleSidebar } = useProSidebar()
  const {
    data: portfolioPNL,
    isLoading,
    isFetching,
  } = useQuery(pnlQuery(portfolioSlug, range, benchmarkSlug))

  useEffect(() => {
    if (!isLoading && !isFetching && !!portfolioPNL) {
      toggleSidebar(false)
    }
  }, [portfolioPNL, isLoading, isFetching, toggleSidebar])

  if (isLoading) return <LoadingFull />

  return (
    <HistoricalPNLPage portfolioPNL={portfolioPNL} isFetching={isFetching} />
  )
}

export default HistoricalPage
