import { useTheme } from "@aws-amplify/ui-react"
import React from "react"
import { pnlRowStyle, formatDate } from "../../../../lib/utils"
import RespText from "../../../../components/RespText"
import AuthHOC from "../../../../components/AmplifyAuth/AuthHOC"

const PnlDepositMarker = ({ deposit }) => (
  <AuthHOC group="depositWithdraw">
    <span style={{ position: "relative" }}>
      {deposit > 0 ? (
        <span
          style={{
            color: "rgba(4, 255, 4, .9)",
            position: "absolute",
            left: -15,
          }}
        >
          D
        </span>
      ) : deposit < 0 ? (
        <span
          style={{
            color: "rgba(255, 80, 0, .9)",
            position: "absolute",
            left: -15,
          }}
        >
          W
        </span>
      ) : null}
    </span>
  </AuthHOC>
)

const PnlDataRow = ({
  rowData,
  leverageToDisplay,
  portLeverage,
  showLeverage = true,
  noDollar = false,
}) => {
  const {
    tokens: {
      colors: {
        metagora: {
          value_color: { value },
        },
      },
    },
  } = useTheme()
  const leverage =
    leverageToDisplay === "REALIZED_LEVERAGE"
      ? rowData?.realized_leverage
      : leverageToDisplay === "LEVERAGE_CASH"
      ? rowData?.leverage_cash
      : rowData?.leverage

  return (
    <tr key={rowData?.period}>
      <td>
        <RespText
          computer={formatDate(rowData?.period)}
          mobile={formatDate(rowData?.period, "DD-MMM")}
        />
      </td>
      {noDollar && (
        <td>
          <PnlDepositMarker deposit={rowData?.deposit} />$
          {rowData?.portfolio_value?.toLocaleString()}
        </td>
      )}
      {noDollar && (
        <td style={{ color: value(rowData?.daily_return) }}>
          ${rowData?.daily_return?.toLocaleString()}
        </td>
      )}
      {noDollar && (
        <td style={{ color: value(rowData?.cumulative_return) }}>
          ${rowData?.cumulative_return?.toLocaleString()}
        </td>
      )}
      <td style={{ color: value(rowData?.daily_return_pct) }}>
        {rowData?.daily_return_pct?.toFixed(2)}%
      </td>
      <td style={{ color: value(rowData?.cumulative_return_pct) }}>
        {rowData?.cumulative_return_pct?.toFixed(2)}%
      </td>
      {/* {!noDollar && (
        <>
          <td style={{ color: value(rowData?.return_cash) }}>
            {rowData?.return_cash?.toFixed(2)}%
          </td>
          <td style={{ color: value(rowData?.cumulative_return_cash) }}>
            {rowData?.cumulative_return_cash?.toFixed(2)}%
          </td>
        </>
      )} */}
      {showLeverage && (
        <td style={pnlRowStyle(leverage, portLeverage, 3)}>
          {leverage?.toFixed(2)}
        </td>
      )}
    </tr>
  )
}

export const PnlWeeklyDataRow = ({
  rowData,
  leverageToDisplay,
  portLeverage,
}) => {
  const {
    tokens: {
      colors: {
        metagora: {
          value_color: { value },
        },
      },
    },
  } = useTheme()
  const leverage =
    leverageToDisplay === "REALIZED_LEVERAGE"
      ? rowData?.realized_leverage
      : leverageToDisplay === "LEVERAGE_CASH"
      ? rowData?.leverage
      : rowData?.leverage

  return (
    <tr key={rowData?.period}>
      <td>
        <RespText
          computer={formatDate(rowData?.period)}
          mobile={formatDate(rowData?.period, "DD-MMM")}
        />
      </td>
      <td>${rowData?.portfolio_value?.toLocaleString()}</td>
      <td style={{ color: value(rowData?.daily_return) }}>
        ${rowData?.daily_return?.toLocaleString()}
      </td>
      <td style={{ color: value(rowData?.cumulative_return) }}>
        ${rowData?.cumulative_return?.toLocaleString()}
      </td>
      <td style={{ color: value(rowData?.rebased) }}>
        {rowData?.rebased?.toFixed(2)}%
      </td>

      {/* <td style={{ color: value(rowData?.return_cash) }}>
        {rowData?.return_cash?.toFixed(2)}%
      </td> */}
      <td style={pnlRowStyle(leverage, portLeverage, 3)}>
        {leverage.toFixed(2)}
      </td>
    </tr>
  )
}

export default PnlDataRow
