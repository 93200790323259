import { Card, Flex, Heading } from "@aws-amplify/ui-react"
import { isRouteErrorResponse, useRouteError } from "react-router-dom"

export const PortfolioRouteErrorBoundary = () => {
  const error = useRouteError()
  if (!isRouteErrorResponse(error)) {
    // console.log(error)
    throw error
  }
  console.log(error)
  return (
    <Card>
      <Heading>Portfolio Error</Heading>
      <Flex>
        <pre>{JSON.stringify(error, true, 2)}</pre>
      </Flex>
    </Card>
  )
}
